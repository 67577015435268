export const environment = {
  firebase: {
    apiKey: 'AIzaSyBW0zuApEMXYV5gDsKJxtBHfQJVX9ZcGzU',
    authDomain: 'vagus-tech-staging.firebaseapp.com',
    projectId: 'vagus-tech-staging',
    storageBucket: 'vagus-tech-staging.appspot.com',
    messagingSenderId: '30879875387',
    appId: '1:30879875387:web:7e2104c35cc537f269771e',
    measurementId: 'G-SFBD55LGFX',
    rechaptchaToken: '6LdOUGIoAAAAALos3n9AD_7UcALaOzA-HGkxfZ9X',
    appCheckDebugToken: false,
  },
  version: require('../../package.json').version,
  production: true,
  useEmulators: false,
  name: 'staging',
  sentryDsn:
    'https://06787a17f1e742069dbcaab9090da030@o4505522962759680.ingest.sentry.io/4505523936231424',
  apiUrl: 'https://stg.finapsys.cl/api',
};
